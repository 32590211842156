@import url("http://fonts.cdnfonts.com/css/rubik");
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("../../vendor/bootstrap-sass/assets/fonts/bootstrap/glyphicons-halflings-regular.eot");
  src: url("../../vendor/bootstrap-sass/assets/fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../../vendor/bootstrap-sass/assets/fonts/bootstrap/glyphicons-halflings-regular.woff2") format("woff2"), url("../../vendor/bootstrap-sass/assets/fonts/bootstrap/glyphicons-halflings-regular.woff") format("woff"), url("../../vendor/bootstrap-sass/assets/fonts/bootstrap/glyphicons-halflings-regular.ttf") format("truetype"), url("../../vendor/bootstrap-sass/assets/fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
  content: "\002a";
}

.glyphicon-plus:before {
  content: "\002b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}

.glyphicon-minus:before {
  content: "\2212";
}

.glyphicon-cloud:before {
  content: "\2601";
}

.glyphicon-envelope:before {
  content: "\2709";
}

.glyphicon-pencil:before {
  content: "\270f";
}

.glyphicon-glass:before {
  content: "\e001";
}

.glyphicon-music:before {
  content: "\e002";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-heart:before {
  content: "\e005";
}

.glyphicon-star:before {
  content: "\e006";
}

.glyphicon-star-empty:before {
  content: "\e007";
}

.glyphicon-user:before {
  content: "\e008";
}

.glyphicon-film:before {
  content: "\e009";
}

.glyphicon-th-large:before {
  content: "\e010";
}

.glyphicon-th:before {
  content: "\e011";
}

.glyphicon-th-list:before {
  content: "\e012";
}

.glyphicon-ok:before {
  content: "\e013";
}

.glyphicon-remove:before {
  content: "\e014";
}

.glyphicon-zoom-in:before {
  content: "\e015";
}

.glyphicon-zoom-out:before {
  content: "\e016";
}

.glyphicon-off:before {
  content: "\e017";
}

.glyphicon-signal:before {
  content: "\e018";
}

.glyphicon-cog:before {
  content: "\e019";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-home:before {
  content: "\e021";
}

.glyphicon-file:before {
  content: "\e022";
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-road:before {
  content: "\e024";
}

.glyphicon-download-alt:before {
  content: "\e025";
}

.glyphicon-download:before {
  content: "\e026";
}

.glyphicon-upload:before {
  content: "\e027";
}

.glyphicon-inbox:before {
  content: "\e028";
}

.glyphicon-play-circle:before {
  content: "\e029";
}

.glyphicon-repeat:before {
  content: "\e030";
}

.glyphicon-refresh:before {
  content: "\e031";
}

.glyphicon-list-alt:before {
  content: "\e032";
}

.glyphicon-lock:before {
  content: "\e033";
}

.glyphicon-flag:before {
  content: "\e034";
}

.glyphicon-headphones:before {
  content: "\e035";
}

.glyphicon-volume-off:before {
  content: "\e036";
}

.glyphicon-volume-down:before {
  content: "\e037";
}

.glyphicon-volume-up:before {
  content: "\e038";
}

.glyphicon-qrcode:before {
  content: "\e039";
}

.glyphicon-barcode:before {
  content: "\e040";
}

.glyphicon-tag:before {
  content: "\e041";
}

.glyphicon-tags:before {
  content: "\e042";
}

.glyphicon-book:before {
  content: "\e043";
}

.glyphicon-bookmark:before {
  content: "\e044";
}

.glyphicon-print:before {
  content: "\e045";
}

.glyphicon-camera:before {
  content: "\e046";
}

.glyphicon-font:before {
  content: "\e047";
}

.glyphicon-bold:before {
  content: "\e048";
}

.glyphicon-italic:before {
  content: "\e049";
}

.glyphicon-text-height:before {
  content: "\e050";
}

.glyphicon-text-width:before {
  content: "\e051";
}

.glyphicon-align-left:before {
  content: "\e052";
}

.glyphicon-align-center:before {
  content: "\e053";
}

.glyphicon-align-right:before {
  content: "\e054";
}

.glyphicon-align-justify:before {
  content: "\e055";
}

.glyphicon-list:before {
  content: "\e056";
}

.glyphicon-indent-left:before {
  content: "\e057";
}

.glyphicon-indent-right:before {
  content: "\e058";
}

.glyphicon-facetime-video:before {
  content: "\e059";
}

.glyphicon-picture:before {
  content: "\e060";
}

.glyphicon-map-marker:before {
  content: "\e062";
}

.glyphicon-adjust:before {
  content: "\e063";
}

.glyphicon-tint:before {
  content: "\e064";
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-share:before {
  content: "\e066";
}

.glyphicon-check:before {
  content: "\e067";
}

.glyphicon-move:before {
  content: "\e068";
}

.glyphicon-step-backward:before {
  content: "\e069";
}

.glyphicon-fast-backward:before {
  content: "\e070";
}

.glyphicon-backward:before {
  content: "\e071";
}

.glyphicon-play:before {
  content: "\e072";
}

.glyphicon-pause:before {
  content: "\e073";
}

.glyphicon-stop:before {
  content: "\e074";
}

.glyphicon-forward:before {
  content: "\e075";
}

.glyphicon-fast-forward:before {
  content: "\e076";
}

.glyphicon-step-forward:before {
  content: "\e077";
}

.glyphicon-eject:before {
  content: "\e078";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-remove-sign:before {
  content: "\e083";
}

.glyphicon-ok-sign:before {
  content: "\e084";
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.glyphicon-info-sign:before {
  content: "\e086";
}

.glyphicon-screenshot:before {
  content: "\e087";
}

.glyphicon-remove-circle:before {
  content: "\e088";
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-ban-circle:before {
  content: "\e090";
}

.glyphicon-arrow-left:before {
  content: "\e091";
}

.glyphicon-arrow-right:before {
  content: "\e092";
}

.glyphicon-arrow-up:before {
  content: "\e093";
}

.glyphicon-arrow-down:before {
  content: "\e094";
}

.glyphicon-share-alt:before {
  content: "\e095";
}

.glyphicon-resize-full:before {
  content: "\e096";
}

.glyphicon-resize-small:before {
  content: "\e097";
}

.glyphicon-exclamation-sign:before {
  content: "\e101";
}

.glyphicon-gift:before {
  content: "\e102";
}

.glyphicon-leaf:before {
  content: "\e103";
}

.glyphicon-fire:before {
  content: "\e104";
}

.glyphicon-eye-open:before {
  content: "\e105";
}

.glyphicon-eye-close:before {
  content: "\e106";
}

.glyphicon-warning-sign:before {
  content: "\e107";
}

.glyphicon-plane:before {
  content: "\e108";
}

.glyphicon-calendar:before {
  content: "\e109";
}

.glyphicon-random:before {
  content: "\e110";
}

.glyphicon-comment:before {
  content: "\e111";
}

.glyphicon-magnet:before {
  content: "\e112";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-retweet:before {
  content: "\e115";
}

.glyphicon-shopping-cart:before {
  content: "\e116";
}

.glyphicon-folder-close:before {
  content: "\e117";
}

.glyphicon-folder-open:before {
  content: "\e118";
}

.glyphicon-resize-vertical:before {
  content: "\e119";
}

.glyphicon-resize-horizontal:before {
  content: "\e120";
}

.glyphicon-hdd:before {
  content: "\e121";
}

.glyphicon-bullhorn:before {
  content: "\e122";
}

.glyphicon-bell:before {
  content: "\e123";
}

.glyphicon-certificate:before {
  content: "\e124";
}

.glyphicon-thumbs-up:before {
  content: "\e125";
}

.glyphicon-thumbs-down:before {
  content: "\e126";
}

.glyphicon-hand-right:before {
  content: "\e127";
}

.glyphicon-hand-left:before {
  content: "\e128";
}

.glyphicon-hand-up:before {
  content: "\e129";
}

.glyphicon-hand-down:before {
  content: "\e130";
}

.glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.glyphicon-globe:before {
  content: "\e135";
}

.glyphicon-wrench:before {
  content: "\e136";
}

.glyphicon-tasks:before {
  content: "\e137";
}

.glyphicon-filter:before {
  content: "\e138";
}

.glyphicon-briefcase:before {
  content: "\e139";
}

.glyphicon-fullscreen:before {
  content: "\e140";
}

.glyphicon-dashboard:before {
  content: "\e141";
}

.glyphicon-paperclip:before {
  content: "\e142";
}

.glyphicon-heart-empty:before {
  content: "\e143";
}

.glyphicon-link:before {
  content: "\e144";
}

.glyphicon-phone:before {
  content: "\e145";
}

.glyphicon-pushpin:before {
  content: "\e146";
}

.glyphicon-usd:before {
  content: "\e148";
}

.glyphicon-gbp:before {
  content: "\e149";
}

.glyphicon-sort:before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.glyphicon-sort-by-order:before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.glyphicon-unchecked:before {
  content: "\e157";
}

.glyphicon-expand:before {
  content: "\e158";
}

.glyphicon-collapse-down:before {
  content: "\e159";
}

.glyphicon-collapse-up:before {
  content: "\e160";
}

.glyphicon-log-in:before {
  content: "\e161";
}

.glyphicon-flash:before {
  content: "\e162";
}

.glyphicon-log-out:before {
  content: "\e163";
}

.glyphicon-new-window:before {
  content: "\e164";
}

.glyphicon-record:before {
  content: "\e165";
}

.glyphicon-save:before {
  content: "\e166";
}

.glyphicon-open:before {
  content: "\e167";
}

.glyphicon-saved:before {
  content: "\e168";
}

.glyphicon-import:before {
  content: "\e169";
}

.glyphicon-export:before {
  content: "\e170";
}

.glyphicon-send:before {
  content: "\e171";
}

.glyphicon-floppy-disk:before {
  content: "\e172";
}

.glyphicon-floppy-saved:before {
  content: "\e173";
}

.glyphicon-floppy-remove:before {
  content: "\e174";
}

.glyphicon-floppy-save:before {
  content: "\e175";
}

.glyphicon-floppy-open:before {
  content: "\e176";
}

.glyphicon-credit-card:before {
  content: "\e177";
}

.glyphicon-transfer:before {
  content: "\e178";
}

.glyphicon-cutlery:before {
  content: "\e179";
}

.glyphicon-header:before {
  content: "\e180";
}

.glyphicon-compressed:before {
  content: "\e181";
}

.glyphicon-earphone:before {
  content: "\e182";
}

.glyphicon-phone-alt:before {
  content: "\e183";
}

.glyphicon-tower:before {
  content: "\e184";
}

.glyphicon-stats:before {
  content: "\e185";
}

.glyphicon-sd-video:before {
  content: "\e186";
}

.glyphicon-hd-video:before {
  content: "\e187";
}

.glyphicon-subtitles:before {
  content: "\e188";
}

.glyphicon-sound-stereo:before {
  content: "\e189";
}

.glyphicon-sound-dolby:before {
  content: "\e190";
}

.glyphicon-sound-5-1:before {
  content: "\e191";
}

.glyphicon-sound-6-1:before {
  content: "\e192";
}

.glyphicon-sound-7-1:before {
  content: "\e193";
}

.glyphicon-copyright-mark:before {
  content: "\e194";
}

.glyphicon-registration-mark:before {
  content: "\e195";
}

.glyphicon-cloud-download:before {
  content: "\e197";
}

.glyphicon-cloud-upload:before {
  content: "\e198";
}

.glyphicon-tree-conifer:before {
  content: "\e199";
}

.glyphicon-tree-deciduous:before {
  content: "\e200";
}

.glyphicon-cd:before {
  content: "\e201";
}

.glyphicon-save-file:before {
  content: "\e202";
}

.glyphicon-open-file:before {
  content: "\e203";
}

.glyphicon-level-up:before {
  content: "\e204";
}

.glyphicon-copy:before {
  content: "\e205";
}

.glyphicon-paste:before {
  content: "\e206";
}

.glyphicon-alert:before {
  content: "\e209";
}

.glyphicon-equalizer:before {
  content: "\e210";
}

.glyphicon-king:before {
  content: "\e211";
}

.glyphicon-queen:before {
  content: "\e212";
}

.glyphicon-pawn:before {
  content: "\e213";
}

.glyphicon-bishop:before {
  content: "\e214";
}

.glyphicon-knight:before {
  content: "\e215";
}

.glyphicon-baby-formula:before {
  content: "\e216";
}

.glyphicon-tent:before {
  content: "\26fa";
}

.glyphicon-blackboard:before {
  content: "\e218";
}

.glyphicon-bed:before {
  content: "\e219";
}

.glyphicon-apple:before {
  content: "\f8ff";
}

.glyphicon-erase:before {
  content: "\e221";
}

.glyphicon-hourglass:before {
  content: "\231b";
}

.glyphicon-lamp:before {
  content: "\e223";
}

.glyphicon-duplicate:before {
  content: "\e224";
}

.glyphicon-piggy-bank:before {
  content: "\e225";
}

.glyphicon-scissors:before {
  content: "\e226";
}

.glyphicon-bitcoin:before {
  content: "\e227";
}

.glyphicon-btc:before {
  content: "\e227";
}

.glyphicon-xbt:before {
  content: "\e227";
}

.glyphicon-yen:before {
  content: "\00a5";
}

.glyphicon-jpy:before {
  content: "\00a5";
}

.glyphicon-ruble:before {
  content: "\20bd";
}

.glyphicon-rub:before {
  content: "\20bd";
}

.glyphicon-scale:before {
  content: "\e230";
}

.glyphicon-ice-lolly:before {
  content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}

.glyphicon-education:before {
  content: "\e233";
}

.glyphicon-option-horizontal:before {
  content: "\e234";
}

.glyphicon-option-vertical:before {
  content: "\e235";
}

.glyphicon-menu-hamburger:before {
  content: "\e236";
}

.glyphicon-modal-window:before {
  content: "\e237";
}

.glyphicon-oil:before {
  content: "\e238";
}

.glyphicon-grain:before {
  content: "\e239";
}

.glyphicon-sunglasses:before {
  content: "\e240";
}

.glyphicon-text-size:before {
  content: "\e241";
}

.glyphicon-text-color:before {
  content: "\e242";
}

.glyphicon-text-background:before {
  content: "\e243";
}

.glyphicon-object-align-top:before {
  content: "\e244";
}

.glyphicon-object-align-bottom:before {
  content: "\e245";
}

.glyphicon-object-align-horizontal:before {
  content: "\e246";
}

.glyphicon-object-align-left:before {
  content: "\e247";
}

.glyphicon-object-align-vertical:before {
  content: "\e248";
}

.glyphicon-object-align-right:before {
  content: "\e249";
}

.glyphicon-triangle-right:before {
  content: "\e250";
}

.glyphicon-triangle-left:before {
  content: "\e251";
}

.glyphicon-triangle-bottom:before {
  content: "\e252";
}

.glyphicon-triangle-top:before {
  content: "\e253";
}

.glyphicon-console:before {
  content: "\e254";
}

.glyphicon-superscript:before {
  content: "\e255";
}

.glyphicon-subscript:before {
  content: "\e256";
}

.glyphicon-menu-left:before {
  content: "\e257";
}

.glyphicon-menu-right:before {
  content: "\e258";
}

.glyphicon-menu-down:before {
  content: "\e259";
}

.glyphicon-menu-up:before {
  content: "\e260";
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav:before, .nav:after {
  display: table;
  content: " ";
}

.nav:after {
  clear: both;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}

.nav > li.disabled > a {
  color: #777777;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-pills > li {
  float: left;
}

.nav-pills > li > a {
  border-radius: 4px;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #337ab7;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
  width: 100%;
}

.nav-justified > li, .nav-tabs.nav-justified > li {
  float: none;
}

.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center;
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified > li, .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0;
}

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

table {
  background-color: transparent;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

[type="checkbox"] + label {
  display: inline;
}

select.browser-default {
  border: 1px solid #ccc;
}

body {
  background: #f5f5f5;
}

.admin-panel .card {
  overflow: visible;
}

.rotate {
  font-size: .7em;
}

table.reset {
  width: auto;
}

table.reset td, table.reset tr {
  padding: 0;
  margin: 0;
}

.panel-img.portrait {
  max-width: 50px;
}

.panel-img.landscape {
  max-height: 50px;
}

table i {
  vertical-align: middle;
}

#dashboard-table th, #dashboard-table td {
  text-align: center;
  border: 1px solid #ccc;
}

.vars-list .collection {
  max-height: 400px;
  overflow: auto;
}

/* Fix mysterious color conflict */
.side-nav li {
  padding: 0 !important;
}

.note-editor {
  border-color: #fafafa;
}

.note-editor .btn:hover, .note-editor .btn-large:hover {
  background-color: #c3c3c3 !important;
}

.note-editor .note-toolbar {
  background: #fafafa;
}

.note-editor .note-resizebar {
  background: #fafafa;
}

.note-editor .note-resizebar .note-icon-bar {
  border-color: #adadad;
}

#save-warning {
  height: 62px;
}

#save-warning button {
  margin-top: -5px;
  margin-left: 10px;
}

.text-button {
  border: none;
  background-color: inherit;
  padding: 7px 14px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
}

.text-button:hover {
  background: #eee;
}

.stick {
  margin-top: 0 !important;
  position: fixed;
  top: 0;
  z-index: 10000;
  border-radius: 0 0 0.5em 0.5em;
}

main {
  position: relative;
  top: 70px;
}

.block {
  display: block;
}

.container {
  width: 90%;
}

html {
  overflow-x: hidden;
}

.form-group {
  margin-bottom: 0.5em;
}

.card-image.crop {
  height: 400px;
  overflow: hidden;
}

.crop-offer {
  height: 300px;
  overflow: hidden;
}

#logo-container {
  padding: 1em 1em 0 1em;
}

.side-nav a.brand-logo#logo-container {
  height: auto;
  line-height: inherit;
}

.side-nav a.menu-item {
  padding: 0 16px;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active a {
  color: #444444 !important;
}

.side-nav .divider {
  margin: 0 !important;
}

label {
  width: 100%;
}

header, main, footer {
  padding-left: 300px;
  left: 0;
}

nav {
  width: calc(100% - 300px);
}

.autocomplete-wrapper .preloader-wrapper {
  float: left;
  margin-top: 20px;
}

.autocomplete-wrapper .form-group {
  margin-left: 55px;
}

#render-layout tr {
  line-height: 0;
}

.button-collapse {
  display: none !important;
}

#article-output-table td {
  padding: 0;
  width: 50%;
}

#article-output-table td.right-col {
  padding-left: 1em;
}

.selectize-input .item {
  padding: .3em 0;
}

#checkbox-reverse {
  margin: 1em 0;
}

#offer-checkbox-accepted-rejected {
  margin-bottom: 0;
}

#offer-checkbox-accepted-rejected .col {
  padding: 0;
}

@media only screen and (max-width: 1279px) {
  header, main, footer {
    padding-left: 0;
  }
  nav {
    width: 100%;
  }
  .button-collapse {
    display: inline-block !important;
  }
}

nav {
  background: #388E3C;
  position: fixed;
  z-index: 3000;
  /* Note-editor toolbar is at 2000 */
}

.modal-overlay {
  z-index: 3001 !important;
}

.modal {
  z-index: 3002 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.tabs .tab a {
  color: #4CAF50;
}

.tabs .tab a:hover {
  color: #C8E6C9;
}

.tabs .indicator {
  background-color: #4CAF50;
}

.card.decision i.material-icons {
  font-size: 6em;
}

#wrapper-content-textarea {
  margin-top: 2em;
}

#choose-event {
  height: 400px;
  color: #ccc;
}

#choose-event .valign {
  margin: auto;
}

#choose-event p {
  font-size: 1.8em;
}

#choose-event i {
  font-size: 3em;
}

.sortable.collection {
  overflow: visible;
}

.ui-sortable-handle {
  cursor: move;
}

.clickable-row {
  cursor: pointer;
}

.status-indicator {
  font-size: 1em;
  padding: 1em;
}

.new:after {
  content: "" !important;
}

.collection {
  overflow: visible;
}

.compare-table .error {
  border: 1px solid red;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.green {
  background-color: green;
}

.dark-green {
  background-color: #194E1B;
}

.teal {
  background-color: #009688;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

.wizard {
  background: #fff;
}

.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  margin-top: 0;
  border-bottom: none;
}

.wizard .nav-tabs > li {
  width: 10%;
}

.wizard .nav-tabs > li a {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}

.wizard > div.wizard-inner {
  position: relative;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 50px;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

span.round-tab {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 25px;
}

span.round-tab i {
  color: #555555;
}

span.count-tab {
  width: 70px;
  line-height: 12px;
  display: inline-block;
  z-index: 0;
  position: absolute;
  left: 10px;
  text-align: right;
  font-size: 15px;
  top: -12px;
  color: #555555;
}

.wizard li.active span.round-tab {
  background: #fff;
  border: 2px solid #0eb015;
}

.wizard li.active span.round-tab i {
  color: #0eb015;
}

.wizard li.rejected span.round-tab {
  background: #fff;
  border: 2px solid #db0018;
}

.wizard li.rejected span.round-tab i {
  color: #db0018;
}

.short-textarea {
  min-height: 4rem;
}

.vertical-bottom {
  vertical-align: bottom;
}

.tiny-gutter, .tiny-gutter input {
  margin-bottom: 5px !important;
}

.partial-installation {
  /* TODO: we should have only 1 place where it's configured */
  background-color: #E5BAAB;
}

.installation-for-malfunction {
  /* TODO: we should have only 1 place where it's configured */
  background-color: #B9B9B9;
}

.gone {
  display: none;
  height: 0;
}

.alligned-with-time-input {
  padding-top: 7px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.authentication-bg.enlarged {
  min-height: 100px;
}

.bg-pattern {
  background-image: url("/assets/images/bg-pattern-2.png");
  background-size: cover;
}

body.authentication-bg {
  background-color: gray;
  background-size: cover;
  background-position: center;
}

body.authentication-bg-pattern {
  background-image: url("/assets/images/bg-pattern.png");
  background-color: #4CAF50;
}

.logout-icon {
  width: 140px;
}

.client-login {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 40%), url("/assets/images/zelfopwekken/zelfopwekken-15.png") center center/100% auto no-repeat;
  background-size: cover;
  width: 100%;
  font-family: 'Rubik', sans-serif;
}

.client-login .relative {
  position: relative;
}

.client-login .header {
  position: absolute;
  width: 100%;
  justify-content: space-between;
}

.client-login .header .service-text {
  color: white;
  text-decoration: underline;
  text-underline-offset: 10px;
}

.client-login .login-content {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  height: 100vh;
  gap: 10rem;
}

.client-login .login-content .login-box {
  width: 100%;
}

.client-login .login-content .login-box .center-circle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgrey;
  height: 125px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 125px;
  padding: 25px;
}

.client-login .login-content .login-box .card {
  width: 100%;
  border-radius: 1rem;
}

.client-login .login-content .login-box .card .back {
  padding: 20px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: revert;
}

.client-login .login-content .login-box .card .back a {
  color: black;
  text-decoration: none;
}

.client-login .login-content .login-box .card .card-body {
  padding: 2.5rem;
}

.client-login .login-content .login-box .card .card-body .forgot-password-link-bottom {
  display: none;
  color: #4A4A4A;
  padding: 0.50rem;
}

@media screen and (max-width: 468px) {
  .client-login .login-content .login-box .card .card-body .forgot-password-link {
    display: none;
  }
  .client-login .login-content .login-box .card .card-body .forgot-password-link-bottom {
    display: block;
  }
}

@media screen and (min-width: 469px) {
  .client-login .login-content .login-box .card .card-body .forgot-password-link {
    position: absolute;
  }
  .client-login .login-content .login-box .card .card-body .forgot-password-link-bottom {
    display: none !important;
  }
}

.client-login .login-content .login-box .card .card-body .input-group {
  color: #212529;
  background-color: #F9F9F9;
  border: 1px solid #DFDFDF;
  padding: 10px;
  border-radius: 0.5rem;
}

.client-login .login-content .login-box .card .card-body .input-group .input-group-text {
  background-color: transparent;
  border: 0;
  font-size: 1.1rem;
  padding: 0.7rem 0.75rem;
}

.client-login .login-content .login-box .card .card-body .input-group .input-group-text img {
  width: 1.5rem;
}

.client-login .login-content .login-box .card .card-body .input-group input.form-control {
  background-color: #F9F9F9;
  border: 0;
}

.client-login .login-content .login-box .card .card-body .input-group input.form-control:focus, .client-login .login-content .login-box .card .card-body .input-group input.form-control:hover {
  border: none;
  outline: none;
  box-shadow: none;
}

.client-login .login-content .login-box .card .card-body .input-group .forgot-password-link {
  position: absolute;
  right: 0;
  color: #4A4A4A;
  z-index: 999;
  padding: 0.50rem;
  font-size: 1rem;
}

.client-login .login-content .login-box .card .card-body .form-check {
  color: #4A4A4A;
  letter-spacing: 0px;
}

.client-login .login-content .login-box .card .card-body .form-check .form-check-input {
  margin-right: 15px;
  width: 1.3rem;
  height: 1.3rem;
  background-color: #F9F9F9;
  border: 1px solid #DFDFDF;
}

.client-login .login-content .login-box .card .card-body .form-check .form-check-label {
  line-height: 25px;
}

.client-login .login-content .login-box .card .card-body .form-check input.form-check-input:focus, .client-login .login-content .login-box .card .card-body .form-check input.form-check-input:hover {
  border: 1px solid #DFDFDF;
  outline: none;
  box-shadow: none;
}

.client-login .login-content .login-box .card .card-body .form-check input.form-check-input:checked {
  background-image: url("/assets/images/zelfopwekken/Oranje_vink.svg");
  width: 20px;
}

.client-login .login-content .login-box .card .card-body .primary-action {
  padding: 1rem;
  background-color: #FC7A09;
  color: white;
  display: block;
  width: 100%;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  font-size: 1rem;
}

.client-login .login-content .login-box .card .card-body .primary-action:hover {
  background-color: #E26D08;
}

.client-login .login-content .login-box .login-link {
  width: 100%;
  text-align: center;
}

.client-login .login-content .login-box .login-link a {
  color: black;
  text-decoration: underline;
}

.client-login .login-content .info-box {
  width: 100%;
  color: white;
}

.client-login .login-content .info-box .title {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 2px;
}

.client-login .login-content .info-box .text-line {
  margin-top: 15px;
}

.client-login .login-content .info-box .text-line span {
  margin-left: 15px;
  line-height: 30px;
  letter-spacing: 2px;
}

.client-login .login-content .info-box .sub-text {
  margin-top: 30px;
}

.client-login .login-content .info-box .sub-text .underline {
  text-decoration: underline;
}

.client-login .info-nav {
  font-size: 1.3rem;
  font-weight: 100;
  position: absolute;
  right: 0;
  width: 50%;
  padding: 90px;
  background: white;
  bottom: 0;
  top: 0;
  height: 100vh;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.09);
  line-height: 1.7;
}

.client-login .info-nav h1 {
  letter-spacing: 2px;
}

.client-login .info-nav .action-bar {
  position: absolute;
  top: 50px;
  right: 50px;
}

.client-login .info-nav .action-bar i {
  font-size: 2rem;
}

.client-login .info-nav .phone-box {
  margin-top: 25px;
  padding: 30px;
  background-color: #F8F8F8;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  display: flex;
  gap: 30px;
}

.client-login .info-nav .phone-box .image-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-login .info-nav .phone-box .image-box img {
  height: 3rem;
  margin: auto;
}

.client-login .info-nav .phone-box .text-box {
  line-height: 15px;
}

.client-login .info-nav .phone-box .text-box h2 {
  font-weight: 400;
  margin: 0;
}

.client-login .info-nav .phone-box .text-box span {
  font-size: 1rem;
}

.client-login .info-nav .questions {
  margin-top: 40px;
}

.client-login .info-nav .questions .head:hover {
  cursor: pointer;
  background-color: #eaeaea;
}

.client-login .info-nav .questions .head {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  position: relative;
  font-size: 1rem;
  margin-top: 10px;
  background-color: #F7F7F7;
  padding: 15px 25px;
  border: 1px solid #E9E9E9;
}

.client-login .info-nav .questions .head .action {
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  top: 0;
  height: 100%;
  color: #FC7A0A;
}

.client-login .info-nav .questions .answer {
  padding: 15px 25px;
  background-color: #fafafa;
  font-size: 0.8rem;
  transition: width 600ms ease-out, height 600ms ease-out;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 300ms ease-in 0s;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 991px) {
  .info-box {
    display: none;
  }
  .service-text {
    display: none;
  }
  .header {
    justify-content: center !important;
  }
}
