$fa-font-path: "../../vendor/components-font-awesome/fonts";
$icon-font-path: "../../vendor/bootstrap-sass/assets/fonts/bootstrap/";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";

@import url('http://fonts.cdnfonts.com/css/rubik');


$primary-color-dark:   #388E3C;
$primary-color:        #4CAF50;
$primary-color-light:  #C8E6C9;
$primary-color-text:   #FFFFFF;
$accent-color:         #FF5722;
$primary-text-color:   #212121;
$secondary-text-color: #757575;
$divider-color:        #BDBDBD;
$danger-color:         red;

// Fix broken placement of checkbox and label in select multiple dropdown
[type="checkbox"] + label {
  display: inline;
}
select.browser-default{
  border:1px solid #ccc;
}
body{
  background:#f5f5f5;
}

.admin-panel .card {
  overflow: visible;
}


.rotate{
  font-size: .7em;
}

table{
  &.reset{
    width:auto;

    td, tr{
      padding:0;
      margin: 0;
    }

  }
}

.panel-img{
  &.portrait{
    max-width:50px;
  }

  &.landscape{
    max-height:50px;
  }
}

table i{
  vertical-align: middle;
}

#dashboard-table{
  th, td{
   text-align:center;
    border:1px solid #ccc;
  }
}

.vars-list{
  .collection{
    max-height: 400px;
    overflow: auto;
  }
}

/* Fix mysterious color conflict */

.side-nav li {
  padding:0 !important; // Fix conflict padding with material note
}

.note-editor{
  border-color: #fafafa;

  .btn:hover, .btn-large:hover {
    background-color: #c3c3c3 !important;
  }
  .note-toolbar {
    background: #fafafa;
  }

  .note-resizebar{
    background: #fafafa;

    .note-icon-bar{
      border-color:#adadad;
    }
  }

}

#save-warning{
  height: 62px ;

  button{
    margin-top: -5px;
    margin-left: 10px;
  }
}

.text-button {
  border: none;
  background-color: inherit;
  padding: 7px 14px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
}

.text-button:hover {
  background: #eee;
}

.stick{

  margin-top: 0 !important;
  position: fixed;
  top: 0;
  z-index: 10000;
  border-radius: 0 0 0.5em 0.5em;

}


main{
  position: relative;
  top: 70px;
}


.block{
  display:block;
}

.container{
  width:90%;
}
html{
  overflow-x:hidden;
}

.form-group{
  margin-bottom:0.5em;
}


.card-image.crop{
  height: 400px;
  overflow: hidden;
}
.crop-offer{
  height: 300px;
  overflow: hidden;
}

#logo-container{
  padding:1em 1em 0 1em;
}

.side-nav a.brand-logo#logo-container {
    height : auto;
    line-height: inherit;
}

.side-nav a.menu-item {
    padding : 0 16px;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active {
    background-color: rgba(0, 0, 0, 0.05) !important;
    
    a {
        color: rgb(68, 68, 68) !important;
    }
}

.side-nav .divider {
    margin: 0 !important;
}

label{
  width:100%;
}

header, main, footer {
  padding-left: 300px;
  left:0;
}
nav {
  width: calc(100% - 300px);
}

.autocomplete-wrapper{
  .preloader-wrapper{
    float:left;
    margin-top:20px;
  }
  .form-group{
    margin-left:55px;
  }
}

#render-layout tr{
  line-height:0;
}

.button-collapse{
  display: none !important;
}
#article-output-table{
  td{
    padding:0;
    width:50%;

    &.right-col{
      padding-left:1em;
    }
  }
}
.selectize-input{
  .item{

    //background:#ccc;
    padding:.3em 0;
    //border-radius:10px;
  }
}

#checkbox-reverse{
  margin:1em 0;

}

#offer-checkbox-accepted-rejected{
  margin-bottom: 0;
  .col{
    padding:0;
  }

}


@media only screen and (max-width : 1279px) {
  header, main, footer {
    padding-left: 0;
  }
  nav {
    width: 100%;
  }

  .button-collapse{
    display: inline-block !important;
  }

}

nav{
  background: $primary-color-dark;
  position: fixed;
  z-index: 3000; /* Note-editor toolbar is at 2000 */
}

.modal-overlay {
  z-index: 3001 !important;
}
.modal {
  z-index: 3002 !important;
}

.cursor-pointer {
  cursor:pointer;
}

.tabs{
  .tab{
    a{
      color:$primary-color;

      &:hover{
        color: $primary-color-light;
      }
    }
  }

  .indicator{
    background-color:$primary-color;
  }
}

.card{
  &.decision{
    i.material-icons{
      font-size:6em;
    }
  }
}

#wrapper-content-textarea{
  margin-top:2em;
}

#choose-event{
  height:400px;
  color:#ccc;

  .valign{
    margin:auto;
  }

  p {
    font-size: 1.8em;
  }

  i{
    font-size:3em;
  }
}

.sortable{
  &.collection {
    overflow: visible;
  }
}

.ui-sortable-handle{
  cursor: move;
}


.clickable-row{
  cursor:pointer;
}
.status-indicator{
  font-size:1em;
  padding: 1em;
}

.new:after{
	content: "" !important;
}

.collection{
  overflow: visible;
}

.compare-table {
	.error {
		border: 1px solid red;
	}
}

.circle {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.green {
	background-color: green;
}
.dark-green {
    background-color: #194E1B;
}
.teal {
    background-color: #009688;
}
.red {
	background-color: red;
}
.orange {
	background-color: orange;
}

.wizard {
  background: #fff;

  .nav-tabs {
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: none;

    > li {
      width: 10%;

      a {
        width: 70px;
        height: 70px;
        margin: 20px auto;
        border-radius: 100%;
        padding: 0;
      }  
    } 
  }

  > div.wizard-inner {
    position: relative;
  }

  .tab-pane {
    position: relative;
    padding-top: 50px;
  }
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

span.round-tab {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 25px;

  i {
    color:#555555;
  }
}

span.count-tab {
  width: 70px;
  line-height: 12px;
  display: inline-block;
  z-index: 0;
  position: absolute;
  left: 10px;
  text-align: right;
  font-size: 15px;
  top: -12px;
  color: #555555;
}

.wizard li.active span.round-tab {
  background: #fff;
  border: 2px solid #0eb015;

  i {
    color: #0eb015;
  }  
}

.wizard li.rejected span.round-tab {
  background: #fff;
  border: 2px solid #db0018;

  i {
    color: #db0018;
  }  
}

.short-textarea {
    min-height: 4rem;
}

.vertical-bottom {
    vertical-align: bottom;
}

.tiny-gutter, .tiny-gutter input {
    margin-bottom: 5px !important;
}

.partial-installation {
    /* TODO: we should have only 1 place where it's configured */
    background-color: #E5BAAB;
}

.installation-for-malfunction {
    /* TODO: we should have only 1 place where it's configured */
    background-color: #B9B9B9;
}

.gone {
    display: none;
    height: 0;
}

.alligned-with-time-input {
    padding-top: 7px;
}

.no-margin-bottom {
    margin-bottom: 0px;
}

.authentication-bg.enlarged {
    min-height: 100px;
}

.bg-pattern {
    background-image: url("/assets/images/bg-pattern-2.png");
    background-size: cover;
}

// authentication pages background
body.authentication-bg {
    background-color: gray;
    background-size: cover;
    background-position: center;
}

body.authentication-bg-pattern {
    background-image: url("/assets/images/bg-pattern.png");
    background-color: $primary-color;
}

// Logout page
.logout-icon {
    width: 140px;
}




.client-login {
    background: linear-gradient(0deg
    , rgba(0,0,0,0.48) 0%, rgba(0,0,0,0) 40%), url("/assets/images/zelfopwekken/zelfopwekken-15.png") center center / 100% auto no-repeat;
    background-size: cover;
    width: 100%;
  font-family: 'Rubik', sans-serif;

  .relative {
      position: relative;
    }

    .header {
      position: absolute;
      width: 100%;
      justify-content: space-between;

      .service-text {
        color: white;
        text-decoration: underline;
        text-underline-offset: 10px;
      }
    }

    .login-content {
      justify-content: space-evenly;
      display: flex;
      align-items: center;
      height: 100vh;
      gap: 10rem;
      .login-box {
        width: 100%;

        .center-circle{
            display: block; 
            margin-left: auto; 
            margin-right: auto;
            border: 1px solid lightgrey;    
            height: 125px;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            width: 125px;
            padding: 25px;
        }

        .card {
          width: 100%;
          border-radius: 1rem;

          .back {
            padding: 20px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: revert;

            a {
              color: black;
              text-decoration: none;
            }

          }

          .card-body {
            padding: 2.5rem;

            .forgot-password-link-bottom {
              display:none;
              color:#4A4A4A;
              padding: 0.50rem;
            }

            @media screen and (max-width: 468px) {
              .forgot-password-link {
                display:none;
              }
              .forgot-password-link-bottom {
                display:block;
              }
            }
            @media screen and (min-width: 469px) {
              .forgot-password-link {
                position: absolute;
              }
              .forgot-password-link-bottom {
                display:none !important;
              }
            }


            .input-group {
              color: #212529;
              background-color: #F9F9F9;
              border: 1px solid #DFDFDF;
              padding: 10px;
              border-radius: 0.5rem;

              .input-group-text {
                background-color: transparent;
                border: 0;
                font-size:1.1rem;
                padding: 0.7rem 0.75rem;

                img {
                  width: 1.5rem;
                }

              }
              input.form-control {
                background-color: #F9F9F9;
                border: 0;
              }

              input.form-control:focus, input.form-control:hover{
                border: none;
                outline: none;
                box-shadow: none;
              }

              .forgot-password-link {
                position: absolute;
                right: 0;
                color:#4A4A4A;
                z-index: 999;
                padding: 0.50rem;
                font-size: 1rem;

              }
            }

            .form-check {
              color:#4A4A4A;
              letter-spacing: 0px;
              .form-check-input {
                margin-right: 15px;
                width: 1.3rem;
                height: 1.3rem;
                background-color: #F9F9F9;
                border: 1px solid #DFDFDF;
              }

              .form-check-label {
                line-height: 25px;
              }

              input.form-check-input:focus, input.form-check-input:hover{
                border: 1px solid #DFDFDF;
                outline: none;
                box-shadow: none;
              }

              input.form-check-input:checked {
                background-image: url("/assets/images/zelfopwekken/Oranje_vink.svg");
                width: 20px;
              }


            }

            .primary-action {
              padding: 1rem;
              background-color: #FC7A09;
              color: white;
              display: block;
              width: 100%;
              border-radius: 2rem;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
              font-size: 1rem;
            }

            .primary-action:hover {
              background-color: #E26D08;
            }

            }

        }
        .login-link {
          width: 100%;
          text-align: center;

          a {
            color: black;
            text-decoration: underline;
          }
        }
      }




      .info-box {
        width: 100%;
        color: white;
        .title{
            font-family: 'Roboto Condensed', sans-serif !important;
              font-weight: bold;
              font-size: 2rem;
              line-height: 1;
              letter-spacing: 2px;
          }

        .text-line {
          margin-top: 15px;
          span {
            margin-left: 15px;
            line-height: 30px;
            letter-spacing: 2px;
          }
        }

        .sub-text {
          margin-top: 30px;

          .underline {
            text-decoration: underline;
          }
        }

      }
    }

  .info-nav {
    font-size: 1.3rem;
    font-weight: 100;
    position: absolute;
    right: 0;
    width: 50%;
    padding: 90px;
    background: white;
    bottom: 0;
    top: 0;
    height: 100vh;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.09);
    line-height: 1.7;

    h1 {
      letter-spacing: 2px;
    }

    .action-bar {
      position:absolute;
      top: 50px;
      right: 50px;
      i {
        font-size:2rem;
      }
    }

    .phone-box {
      margin-top: 25px;
      padding: 30px;
      background-color: #F8F8F8;
      border: 1px solid #E9E9E9;
      border-radius: 5px;
      display: flex;
      gap: 30px;

      .image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 3rem;
          margin: auto;
        }
      }

      .text-box {
        line-height: 15px;
        h2 {
          font-weight: 400;
          margin: 0;
        }
        span {
          font-size: 1rem;
        }
      }
    }

    .questions {
      margin-top: 40px;


      .head:hover {
        cursor: pointer;
        background-color: #eaeaea;
      }

      .head {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        position: relative;
        font-size: 1rem;
        margin-top: 10px;
        background-color: #F7F7F7;
        padding: 15px 25px;
        border: 1px solid #E9E9E9;

        .action {
          position: absolute;
          display: flex;
          align-items: center;
          right: 15px;
          top: 0;
          height: 100%;
          color: #FC7A0A;
        }

      }

      .answer {
        padding: 15px 25px;
        background-color: #fafafa;
        font-size: 0.8rem;
        transition: width 600ms ease-out, height 600ms ease-out;
      }

    }

  }


}

.slide-enter-active,
.slide-leave-active
{
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(+100%);
  transition: all 300ms ease-in 0s
}

.pointer {
  cursor: pointer;
}


@media (max-width: 991px) {
  .info-box {
    display: none;
  }
  .service-text {
    display: none;
  }
  .header {
    justify-content: center!important;
  }
}